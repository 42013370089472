import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function CustomDialog({
  open,
  onClose,
  title,
  message,
  positiveButtonText,
  onPositiveButtonClick,
  negativeButtonText,
  onNegativeButtonClick,
  timeout,
  ...others
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    open.set(false);
  };

  const timer = React.useRef();

  useEffect(() => {
    if (timeout > 0 && open.get) {
      timer.current = setTimeout(() => {
        if (onClose) {
          onClose();
        } else {
          handleClose();
        }
      }, timeout);
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [open.get]);

  const positiveButtonClick = () => {
    if (onPositiveButtonClick) {
      onPositiveButtonClick();
    }
    if (onClose) {
      onClose();
    } else {
      handleClose();
    }
  };

  const negativeButtonClick = () => {
    if (onNegativeButtonClick) {
      onNegativeButtonClick();
    }
    if (onClose) {
      onClose();
    } else {
      handleClose();
    }
  };

  return (
    <div>
      <Dialog
        {...others}
        id="dialog"
        fullScreen={fullScreen}
        open={open.get}
        onClose={onClose || handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-message">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {negativeButtonText ? (
            <Button id="negative_button" autoFocus onClick={negativeButtonClick} color="primary">
              {negativeButtonText}
            </Button>
          ) : null}
          <Button id="positive_button" autoFocus onClick={positiveButtonClick} color="primary">
            {positiveButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
CustomDialog.defaultProps = {
  onClose: undefined,
  onPositiveButtonClick: undefined,
  negativeButtonText: undefined,
  onNegativeButtonClick: undefined,
  timeout: 3000,
};
CustomDialog.propTypes = {
  open: PropTypes.shape({
    get: PropTypes.bool,
    set: PropTypes.func,
  }).isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  positiveButtonText: PropTypes.string.isRequired,
  onPositiveButtonClick: PropTypes.func,
  negativeButtonText: PropTypes.string,
  onNegativeButtonClick: PropTypes.func,
  timeout: PropTypes.number,
};
