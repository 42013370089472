import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ResizeTextFieldType, ResizeTextFieldDefaultProps } from '../types';

function ResizeTextField(props) {
  const {
    label,
    placeholder,
    disabled,
    inputProps,
    helperText,
    required,
    name,
    type,
    value,
    onChange,
    onBlur,
    paddingLabel,
    multiline,
    InputProps,
    error,
    select,
    options,
    ...others
  } = props;

  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiTextField-root': {
        paddingTop: `${paddingLabel}px`,
      },
      '& .MuiInputLabel-shrink': {
        fontSize: '16px',
        transform: `translate(0, 1.5px)`,
      },
      '& .MuiFormLabel-root': {
        fontSize: '16px',
        fontFamily: 'sans-serif',
      },
      '& .MuiFormHelperText-root': {
        fontFamily: 'sans-serif',
        fontSize: '16px',
      },
    },
    inputResize: {
      fontSize: '16px',
    },
    labelResize: {
      fontSize: '16px',
      '&$disabled': {
        fontSize: '16px',
        color: `rgba(0, 0, 0, 0.54)`,
      },
    },
    disabled: {},
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        {...others}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        fullWidth
        helperText={helperText}
        required={required}
        name={name}
        type={type}
        value={value}
        select={select}
        inputProps={{ ...inputProps, className: classes.inputResize }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{ ...InputProps }}
        InputLabelProps={{
          classes: {
            root: classes.labelResize,
            disabled: classes.disabled,
          },
          shrink: true,
        }}
        onChange={onChange}
        onBlur={onBlur}
        multiline={multiline}
        error={!!error}
      >
        {select && options}
      </TextField>
    </div>
  );
}

ResizeTextField.defaultProps = ResizeTextFieldDefaultProps;

ResizeTextField.propTypes = ResizeTextFieldType;

export default ResizeTextField;
