import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import * as _ from 'lodash';
import MaterialTable from 'material-table';
import { IconButton } from '@material-ui/core';
import { GET_VASP_LIST } from '../../crud';
import { checkJWTExpired, parseGraphQLErrorMessage } from '../../utils/utils';

function VASPList(props) {
  const { actions, onError, token, isEditable, ...others } = props;

  const [vaspList, setVASPList] = useState([]);

  const [getVASPList] = useLazyQuery(GET_VASP_LIST, {
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (!data) {
        onError(parseGraphQLErrorMessage('data is undefined'));
        return;
      }
      if (_.has(data, 'getVaspList')) {
        const clone = _.cloneDeep(data.getVaspList);
        clone.sort((a, b) => {
          const nameA = a.vasp_name.toUpperCase();
          const nameB = b.vasp_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          const codeA = a.vasp_code.toUpperCase();
          const codeB = b.vasp_code.toUpperCase();
          if (codeA < codeB) {
            return -1;
          }
          if (codeA > codeB) {
            return 1;
          }
          return 0;
        });
        setVASPList(clone);
      } else {
        setVASPList([]);
      }
    },
    onError: (e) => {
      onError(parseGraphQLErrorMessage(e));
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (token && !checkJWTExpired(token)) {
      getVASPList();
    }
  }, []);

  return (
    <MaterialTable
      {...others}
      title=""
      columns={[
        { title: 'Name', field: 'vasp_name', cellStyle: { padding: '1em' } },
        { title: 'VASP Code', field: 'vasp_code' },
        { title: 'Account Created Day', field: 'created_at' },
      ]}
      data={vaspList}
      actions={actions}
      options={{
        headerStyle: { padding: '1em' },
      }}
      // add id for action icon
      components={{
        Action: (actionProps) => {
          const { action, data } = actionProps;
          return (
            <IconButton
              size="medium"
              onClick={(event) => action.onClick(event, data)}
              id={`ActionIcon_${data.tableData.id}`}
            >
              <action.icon style={{ color: 'black' }} />
            </IconButton>
          );
        },
      }}
    />
  );
}

VASPList.defaultProps = {
  onError: () => {},
  isEditable: false,
};

VASPList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.instanceOf(MaterialTable.Icons),
      onClick: PropTypes.func,
    }),
  ).isRequired,
  token: PropTypes.string.isRequired,
  onError: PropTypes.func,
  isEditable: PropTypes.bool,
};

export default VASPList;
