import React from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';

function TextFieldWithClear({ label, setState, fromState }) {
  return (
    <TextField
      label={label}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setState('')}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={fromState || ''}
      onChange={(e) => setState(e.target.value)}
      style={{
        width: '20em',
      }}
    />
  );
}
TextFieldWithClear.defaultProps = {
  fromState: undefined,
};
TextFieldWithClear.propTypes = {
  label: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  fromState: PropTypes.string,
};

export default TextFieldWithClear;
