import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  buttonNormal: {
    width: '12em',
    marginLeft: '1em',
  },
}));

export default function NormalButton({
  disabled,
  buttonText,
  onClick,
  buttonType,
  buttonStyle,
  buttonClassName: customButtonClassName,
  ...other
}) {
  const classes = useStyles();

  const buttonClassName = customButtonClassName || classes.buttonNormal;

  return (
    <Button
      {...other}
      type={buttonType}
      style={buttonStyle}
      variant="outlined"
      className={buttonClassName}
      disabled={disabled}
      onClick={(e) => onClick(e)}
    >
      {buttonText}
    </Button>
  );
}
NormalButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  buttonType: 'button',
  buttonClassName: undefined,
  buttonStyle: {},
};
NormalButton.propTypes = {
  disabled: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonStyle: PropTypes.objectOf(PropTypes.any),
};
