import PropTypes from 'prop-types';

export const Currency = PropTypes.shape({
  groupTitle: PropTypes.string,
  currency_id: PropTypes.string,
  currency_symbol: PropTypes.string,
  currency_name: PropTypes.string,
  is_active: PropTypes.bool,
  is_token: PropTypes.bool,
  addr_extra_info: PropTypes.arrayOf(PropTypes.string),
});
