import React from 'react';
import PropTypes from 'prop-types';
import ResizeTextField from './resize-text-field';
import { ResizeTextFieldType, ResizeTextFieldDefaultProps } from '../types';

function HookFormTextField(props) {
  const { error, parseError, forwardedRef, ...others } = props;
  return (
    <ResizeTextField
      {...others}
      inputRef={forwardedRef}
      error={!!error}
      helperText={error ? parseError(error) : ''}
    />
  );
}

HookFormTextField.defaultProps = {
  ...ResizeTextFieldDefaultProps,
  forwardedRef: () => {},
  error: undefined,
  parseError: () => {},
  value: undefined,
};

HookFormTextField.propTypes = {
  ...ResizeTextFieldType,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  parseError: PropTypes.func,
  forwardedRef: PropTypes.func,
};

export default HookFormTextField;
