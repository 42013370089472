import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, InputAdornment } from '@material-ui/core';

import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Clear as ClearIcon } from '@material-ui/icons';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MIN_FROM_DATE, MAX_TO_DATE } from '../../constants';

function DateTimeWidgetWithClear({
  text,
  setState,
  fromState,
  disableFuture,
  disablePast,
  minDate,
  maxDate,
}) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDateTimePicker
        placeholder={text}
        value={fromState}
        onChange={setState}
        disableFuture={disableFuture}
        disablePast={disablePast}
        format="YYYY/MM/DD HH:mm"
        showTodayButton
        clearable
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setState(null)}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputAdornmentProps={{
          position: 'start',
        }}
        style={{
          marginTop: '0.875em',
          width: '15em',
        }}
        minDate={new Date(minDate || MIN_FROM_DATE)}
        maxDate={new Date(maxDate || MAX_TO_DATE)}
      />
    </MuiPickersUtilsProvider>
  );
}
DateTimeWidgetWithClear.defaultProps = {
  disableFuture: true,
  disablePast: false,
  fromState: undefined,
  minDate: undefined,
  maxDate: undefined,
};
DateTimeWidgetWithClear.propTypes = {
  text: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  fromState: PropTypes.instanceOf(moment),
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};

export default DateTimeWidgetWithClear;
