import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import NormalButton from './normalButton';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  buttonNormal: {
    width: '12em',
    marginLeft: '1em',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function LoadingButton({
  loading,
  done,
  disabled,
  buttonText,
  onClick,
  buttonType,
  buttonStyle,
  timeout,
  buttonClassName: customButtonClassName,
  buttonProgressClassName,
  ...other
}) {
  const classes = useStyles();

  const buttonClassName = classNames(customButtonClassName || classes.buttonNormal, {
    [classes.buttonSuccess]: done.get,
  });

  const timer = React.useRef();

  useEffect(() => {
    if (timeout > 0 && done.get) {
      timer.current = setTimeout(() => {
        done.set(false);
      }, timeout);
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [done.get]);

  return (
    <div className={classes.wrapper}>
      <NormalButton
        {...other}
        buttonType={buttonType}
        buttonStyle={buttonStyle}
        buttonClassName={buttonClassName}
        disabled={disabled || loading}
        onClick={(e) => onClick(e)}
        buttonText={buttonText}
      />

      {loading && (
        <CircularProgress size={24} className={buttonProgressClassName || classes.buttonProgress} />
      )}
    </div>
  );
}
LoadingButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  buttonType: 'button',
  timeout: 0,
  buttonClassName: undefined,
  buttonProgressClassName: undefined,
  buttonStyle: {},
};
LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  done: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  timeout: PropTypes.number,
  buttonClassName: PropTypes.string,
  buttonProgressClassName: PropTypes.string,
  buttonStyle: PropTypes.objectOf(PropTypes.any),
};
