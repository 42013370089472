import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, InputAdornment, Chip, Grid } from '@material-ui/core';
import update from 'immutability-helper';
import { AddRounded as AddIcon } from '@material-ui/icons';

function TextFieldWithMultiValue({ label, setState, fromState }) {
  const [internalState, setInternalState] = useState();

  return (
    <Grid container>
      <Grid item>
        <TextField
          label={label}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    if (internalState) {
                      setState(update(fromState, { $add: [internalState] }));
                      setInternalState('');
                    }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={internalState || ''}
          onChange={(e) => setInternalState(e.target.value)}
          style={{
            width: '20em',
          }}
        />
      </Grid>
      {[...fromState].map((v, index) => (
        <Grid item style={{ marginTop: '0.2em' }} key={index.toString()}>
          <Chip
            label={v}
            onDelete={() => {
              setState(update(fromState, { $remove: [v] }));
            }}
            style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '180px' }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

TextFieldWithMultiValue.propTypes = {
  label: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  fromState: PropTypes.instanceOf(Set).isRequired,
};

export default TextFieldWithMultiValue;
