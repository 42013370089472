import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formLabel: {
    color: '#222b45',
    fontSize: '16px',
    fontFamily: 'sans-serif',
    fontWeight: '600',
  },
  labelRequired: {
    content: '*',
    color: '#ff3d71',
  },
}));

function RequiredLabel(props) {
  const classes = useStyles();
  const { htmlFor, value, isRequired, forwardedRef } = props;
  return (
    <label htmlFor={htmlFor} className={classes.formLabel} ref={forwardedRef}>
      {value}
      {isRequired && <span className={classes.labelRequired}>*</span>}
    </label>
  );
}

RequiredLabel.defaultProps = {
  isRequired: false,
  forwardedRef: React.createRef(),
};

RequiredLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  forwardedRef: PropTypes.shape(React.createRef()),
};

export default RequiredLabel;
