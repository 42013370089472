import PropTypes from 'prop-types';

export const Transaction = PropTypes.shape({
  currency_id: PropTypes.string,
  originator_vasp: PropTypes.shape({
    vasp_code: PropTypes.string,
    addrs: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string,
        addr_extra_info: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
      }),
    ),
  }),
  beneficiary_vasp: PropTypes.shape({
    vasp_code: PropTypes.string,
    addrs: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string,
        addr_extra_info: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
      }),
    ),
  }),
});
