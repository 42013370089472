import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tableHeadRow: {
    backgroundColor: '#DFDFDF',
  },
  tableContainer: {
    marginTop: '1em',
    marginBottom: '1em',
  },
}));

export default function RegulationTable({
  loading,
  data,
  selectable,
  selectedIndex,
  setSelectedIndex,
  idPrefix = '',
}) {
  const classes = useStyles();
  const idp = idPrefix;
  return (
    <TableContainer className={classes.tableContainer} id={`${idp}search-result-table`}>
      {loading && <LinearProgress />}
      <Table>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            {selectable && <TableCell align="center">Select</TableCell>}
            <TableCell align="center">VASP Name</TableCell>
            <TableCell align="center">VASP Other Name</TableCell>
            <TableCell align="center">Supervisory Authority</TableCell>
            <TableCell align="center">Country Code</TableCell>
            <TableCell align="center">Regulatory Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.vaspName}>
              {selectable && (
                <TableCell align="right">
                  <Checkbox
                    id={`${idp}search-result-item-${index}`}
                    checked={selectedIndex === index}
                    onChange={(event) => setSelectedIndex(event.target.checked ? index : -1)}
                  />
                </TableCell>
              )}
              <TableCell align="center">{row.vaspName}</TableCell>
              <TableCell align="center">{row.vaspOtherName}</TableCell>
              <TableCell align="center">{row.supervisoryAuthority}</TableCell>
              <TableCell align="center">{row.countryCode}</TableCell>
              <TableCell align="center" id={`${idp}search-result-table-status-${index}`}>
                {row.regulatoryStatus}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div style={{ textAlign: 'center' }}>
        {!loading && !_.get(data, [0], undefined) && (
          <p id={`${idp}vasp-net-result-not-found`}>Not Found</p>
        )}
      </div>
    </TableContainer>
  );
}

RegulationTable.defaultProps = {
  selectable: false,
  selectedIndex: -1,
  setSelectedIndex: () => {},
  idPrefix: '',
};
RegulationTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  selectable: PropTypes.bool,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  idPrefix: PropTypes.string,
};
