import PropTypes from 'prop-types';

export const ResizeTextFieldType = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.objectOf(PropTypes.any),
  InputProps: PropTypes.objectOf(PropTypes.any),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  paddingLabel: PropTypes.number,
  multiline: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export const ResizeTextFieldDefaultProps = {
  placeholder: '',
  disabled: false,
  inputProps: {},
  InputProps: {},
  helperText: '',
  required: false,
  type: 'text',
  onChange: () => {},
  onBlur: () => {},
  paddingLabel: 10,
  multiline: false,
  error: false,
  fullWidth: true,
  value: undefined,
  select: false,
  options: [],
};
