import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  DateTimePicker,
} from '@material-ui/pickers';
import { createMuiTheme, makeStyles, InputAdornment, IconButton } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickerDTToolbar: {
      toolbar: {
        paddingLeft: 4,
        paddingRight: 4,
      },
    },
  },
});

export default function CustomDateTimePicker({
  id,
  name,
  label,
  disablePast,
  disableFuture,
  disabled,
  selectedDate,
  onDateChange,
  minDate,
  maxDate,
  clearable,
  error,
  helperText,
  parseError,
  allowKeyboardControl,
  paddingLabel,
  ...others
}) {
  const handleDateChange = (val) => {
    onDateChange(val);
  };

  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiInputLabel-shrink': {
        fontSize: '16px',
        transform: `translate(0, 1.5px)`,
      },
      '& .MuiFormLabel-root': {
        fontSize: '16px',
        fontFamily: 'sans-serif',
      },
      '& .MuiFormHelperText-root': {
        fontFamily: 'sans-serif',
        fontSize: '16px',
      },
    },
    inputResize: {
      fontSize: '16px',
      paddingTop: `${paddingLabel}px`,
    },
    labelResize: {
      fontSize: '16px',
      '&$disabled': {
        fontSize: '16px',
        color: `rgba(0, 0, 0, 0.54)`,
      },
    },
    disabled: {},
  }));
  const classes = useStyles();
  const pickerProps = {
    ...others,
    id,
    className: classes.root,
    autoOk: true,
    fullWidth: true,
    variant: 'inline',
    ampm: false,
    label,
    format: 'YYYY/MM/DD HH:mm',
    disablePast,
    disableFuture,
    disabled,
    value: selectedDate,
    name,
    minDate,
    maxDate,
    onChange: handleDateChange,
    InputLabelProps: {
      classes: {
        root: classes.labelResize,
        disabled: classes.disabled,
      },
      shrink: true,
    },
    InputAdornmentProps: { position: 'start' },
    InputProps: {
      className: classes.inputResize,
      endAdornment: !disabled && clearable && (
        <InputAdornment position="end">
          <IconButton
            id={`${id}_clear`}
            onClick={(e) => {
              e.stopPropagation();
              handleDateChange(null);
            }}
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      ),
    },
    error: !!error,
    helperText: error ? helperText || parseError(error) : '',
  };
  return (
    <ThemeProvider theme={materialTheme}>
      <>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {allowKeyboardControl ? (
            <KeyboardDateTimePicker {...pickerProps} />
          ) : (
            <DateTimePicker {...pickerProps} />
          )}
        </MuiPickersUtilsProvider>
      </>
    </ThemeProvider>
  );
}
CustomDateTimePicker.defaultProps = {
  disablePast: false,
  disableFuture: false,
  minDate: moment('1900-01-01'),
  maxDate: moment('2100-01-01'),
  disabled: false,
  clearable: false,
  error: undefined,
  parseError: () => {},
  helperText: '',
  allowKeyboardControl: false,
};

CustomDateTimePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onDateChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment),
  clearable: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  parseError: PropTypes.func,
  helperText: PropTypes.string,
  allowKeyboardControl: PropTypes.bool,
};
