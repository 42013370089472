import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const ShowCloseIconDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, disabled, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          disabled={disabled}
          id="close"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomChildDialog({
  open,
  onClose,
  title,
  positiveButtonText,
  onPositiveButtonClick,
  negativeButtonText,
  onNegativeButtonClick,
  timeout,
  child,
  showDialogActions,
  dividers,
  showCloseIcon,
  disableCloseIcon,
  ...others
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    open.set(false);
  };

  const timer = React.useRef();

  useEffect(() => {
    if (timeout > 0 && open.get) {
      timer.current = setTimeout(() => {
        if (onClose) {
          onClose();
        } else {
          handleClose();
        }
      }, timeout);
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [open.get]);

  const positiveButtonClick = () => {
    if (onPositiveButtonClick) {
      onPositiveButtonClick();
    }
    if (onClose) {
      onClose();
    } else {
      handleClose();
    }
  };

  const negativeButtonClick = () => {
    if (onNegativeButtonClick) {
      onNegativeButtonClick();
    }
    if (onClose) {
      onClose();
    } else {
      handleClose();
    }
  };

  return (
    <div>
      <Dialog
        {...others}
        id="dialog"
        fullScreen={fullScreen}
        open={open.get}
        onClose={onClose || handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {showCloseIcon ? (
          <ShowCloseIconDialogTitle
            id="customized-dialog-title"
            onClose={onClose || handleClose}
            disabled={disableCloseIcon}
          >
            {title}
          </ShowCloseIconDialogTitle>
        ) : (
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        )}

        <DialogContent dividers={dividers}>{child}</DialogContent>
        {showDialogActions ? (
          <DialogActions>
            {negativeButtonText ? (
              <Button id="negative_button" autoFocus onClick={negativeButtonClick} color="primary">
                {negativeButtonText}
              </Button>
            ) : null}
            <Button id="positive_button" autoFocus onClick={positiveButtonClick} color="primary">
              {positiveButtonText}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  );
}
CustomChildDialog.defaultProps = {
  onClose: undefined,
  positiveButtonText: undefined,
  onPositiveButtonClick: undefined,
  negativeButtonText: undefined,
  onNegativeButtonClick: undefined,
  timeout: 3000,
  showDialogActions: true,
  dividers: false,
  showCloseIcon: false,
  disableCloseIcon: false,
};
CustomChildDialog.propTypes = {
  open: PropTypes.shape({
    get: PropTypes.bool,
    set: PropTypes.func,
  }).isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  positiveButtonText: PropTypes.string,
  onPositiveButtonClick: PropTypes.func,
  negativeButtonText: PropTypes.string,
  onNegativeButtonClick: PropTypes.func,
  timeout: PropTypes.number,
  child: PropTypes.element.isRequired,
  showDialogActions: PropTypes.bool,
  dividers: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  disableCloseIcon: PropTypes.bool,
};
