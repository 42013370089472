import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/logo.png';

const useStyles = makeStyles(() => ({
  header: {
    position: 'relative',
    width: '100%',
    padding: '16px 0 0',
  },
  headerContainer: {
    paddingLeft: '17px',
    paddingRight: '30px',
    boxShadow: 'inset 0 -2px 2px 0 rgba(0, 0, 0, 0.15)',
  },
  logo: {
    display: 'inline-block',
    marginBottom: '6px',
    maxWidth: '165px',
  },
}));

function SygnaLogoHeader() {
  const classes = useStyles();
  return (
    <header className={classes.header} role="banner">
      <div className={classes.headerContainer}>
        <img id="logo" className={classes.logo} src={logo} alt="SYGNA Bridge" />
      </div>
    </header>
  );
}

export default SygnaLogoHeader;
