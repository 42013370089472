import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function VASPTitleTypography(props) {
  const { vaspCode, vaspName } = props;
  return (
    <div>
      <Typography variant="subtitle1">{`VASP Code: ${vaspCode}`}</Typography>
      <Typography variant="subtitle1" gutterBottom>
        {`Name: ${vaspName}`}
      </Typography>
    </div>
  );
}

VASPTitleTypography.propTypes = {
  vaspCode: PropTypes.string.isRequired,
  vaspName: PropTypes.string.isRequired,
};

export default VASPTitleTypography;
