import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog from './customDialog';

export default function ErrorDialog({
  open,
  onClose,
  title,
  message,
  positiveButtonText,
  onPositiveButtonClick,
  timeout,
  ...others
}) {
  return (
    <CustomDialog
      {...others}
      open={open}
      onClose={onClose}
      title={title}
      message={message}
      positiveButtonText={positiveButtonText}
      onPositiveButtonClick={onPositiveButtonClick}
      timeout={timeout}
    />
  );
}
ErrorDialog.defaultProps = {
  onClose: undefined,
  title: 'Oops!',
  message: 'Please retry',
  positiveButtonText: 'ok',
  onPositiveButtonClick: undefined,
  timeout: 5000,
};

ErrorDialog.propTypes = {
  open: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  positiveButtonText: PropTypes.string,
  onPositiveButtonClick: PropTypes.func,
  timeout: PropTypes.number,
};
